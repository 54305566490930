import { Paper, Container } from '@mantine/core';
import { AnimatePresence } from "framer-motion"

const AuthLayout = ({ children }) => {
    return (
        <Container size={420} my={40}>
            <Paper 
                withBorder 
                shadow="md" 
                p={30} 
                mt={30} 
                radius="md"
            >    
                <AnimatePresence>
                    {children}
                </AnimatePresence>
            </Paper>
        </Container>
    )
}

export default AuthLayout